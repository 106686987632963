import React, { useState } from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import Pager from '../components/Pager';
import NewsletterCTA from '../components/NewsletterCTA'

import SelectTriangle from '../images/icon-select-triangle.svg'

const BlogContainer = styled.section`
  margin-bottom: 120px;

  .row-two-col {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 55px;
    grid-row-gap: 30px;

    @media(min-width: 800px) {
      grid-template-columns: 205px auto;
    }

    .blog-category-list {
      list-style: none;
      padding: 0;
      margin: 0 0 50px 0;
      display: none;

      @media(min-width: 800px) {
        display: block;
      }

      li {
        margin-bottom: 10px;

        &:hover a {
          color: var(--pink);
          font-size: 22px;
          font-weight: var(--font-bold);

          &::before {
            content: "•";
            margin-right: 7px;
            color: var(--pink);
          }
        }

        a {
          color: var(--white);
          font-size: 22px;
          font-weight: var(--font-bold);
          text-decoration: none;
          line-height: 1;

          &:hover {
            color: var(--pink);
          }
        }
      }
    }

    .mobile-blog-category-list {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      display: block;
      position: relative;

      @media(min-width: 800px) {
        display: none;
      }

      .mobile-blog-category-trigger {
        background-color: var(--white);
        border: 2px solid var(--pink);
        color: var(--black);
        position: relative;
        width: 100%;
        padding: 10px 15px;
        text-align: left;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 5px;
          background-image: url(${SelectTriangle});
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: auto;
        }
      }

      .mobile-blog-category-drop {
        background-color: var(--black);
        border: 2px solid var(--pink);
        padding: 20px;
        list-style: none;
        margin: 0;
        display: none;

        &.mob-cats-active {
          display: block;
          position: absolute;
          top: 46px;
          left: 0;
          width: 100%;
          max-width: 320px;
          box-sizing: border-box;
          z-index: 1;
        }

        li {
          margin-bottom: 12px;
      
          &:last-child {
            margin-bottom: 0;
          }
        }
      
        a {
          color: var(--white);
          font-size: 19px;
          font-weight: var(--font-regular);
          text-decoration: none;
          line-height: 1;
      
          &:hover {
            text-decoration: underline;
            color: var(--pink);
          }
        }
      }
    }

    .blog-contact {
      display: none;

      @media(min-width: 800px) {
        display: block;
      }
    }

    .blog-grid {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 50px;
      row-gap: 80px;

      @media(min-width: 800px) {
        grid-template-columns: 1fr 1fr;
      }

      @media(min-width: 1100px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .blog-item {
        width: 100%;
        max-width: 100%;
        height: auto;

        .gatsby-image-wrapper {
          border: 1px solid var(--pink);
          max-width: 100%;
          width: 100%;
          height: auto;
          max-height: 250px;
          margin-bottom: 20px;

          @media(min-width: 800px) {
            max-height: 158px;
          }
        }

        img {
          object-fit: cover;
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: var(--pink);
          }

          h2 {
            font-size: 26px;
            font-weight: var(--font-regular);
            line-height: 1.15;
            margin-bottom: 15px;
          }
        }

        .blog-categories {
          list-style: none;
          padding: 0;
          margin: 0 0 8px 0;
          display: flex;
          flex-wrap: wrap;

          li:last-child span {
            margin-right: 0;
          }

          a {
            color: var(--pink);
            text-decoration: underline;
            font-weight: var(--font-bold);
            font-size: 18px;
          }

          span {
            color: var(--pink);
            margin-right: 7px;
          }
        }

        .author-info {
          color: var(--gray);
          font-size: 17px;

          a {
            color: var(--gray);
          }
        }
      }
    }
  }
`

function BlogCategory({ pageContext }) {
  const { category } = pageContext

  const [mobCatsActive, setActive] = useState(false)

  const togglemobCats = () => {
    setActive(!mobCatsActive);
  }

  const data = useStaticQuery(graphql`
    {
      categoryList: allContentfulCategories(sort: {fields: categoryTitle, order: ASC}) {
        edges {
          node {
            id
            categoryTitle
            categorySlug
          }
        }
      }
    }
  `)

  return (
    <main>
      <PageTitle title='FinTech Law Blog' paragraph={`Category: ${category.categoryTitle}`} />
      <BlogContainer>
        <div className="container">
            <div className="row-two-col">
              <div className="col-1">
                <ul className="blog-category-list">
                  <li>
                    <a href='/blog/'>All Posts</a>
                  </li>
                  {data.categoryList.edges.map(({ node }) => (
                    <li key={node.id}>
                      <a href={`/category/${node.categorySlug}`}>{node.categoryTitle}</a>
                    </li>   
                  ))}
                </ul>

                <div className="mobile-blog-category-list">
                  <button className="mobile-blog-category-trigger" onClick={togglemobCats} onKeyDown={togglemobCats} tabIndex={0}>Blog Categories</button>
                  <ul className={`mobile-blog-category-drop ${mobCatsActive ? "mob-cats-active" : null}`}>
                    <li>
                      <a href='/blog/'>All Posts</a>
                    </li>
                    {data.categoryList.edges.map(({ node }) => (
                      <li key={node.id}>
                        <a href={`/category/${node.categorySlug}`}>{node.categoryTitle}</a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="blog-contact">
                  <a href='/contact/' className="btn">Contact FinTech Law!</a>
                </div>
              </div>
              <div className="col-2">
                <div className="blog-grid">
                {category.blog_post.map(node => 
                  <article key={node.id} className="blog-item">
                    <a href={`/${node.slug}`}>
                      <GatsbyImage image={node.featuredImage.gatsbyImageData} alt={node.featuredImage.title} />
                    </a>
                    <a href={`/${node.slug}`}>
                      <h2>{node.title}</h2>
                    </a>
                    <ul className="blog-categories">
                      {node.blogCategory && node.blogCategory.map((blogCat, index) =>
                        <li key={blogCat.id}>
                          <a href={`/category/${blogCat.categorySlug}`}>{blogCat.categoryTitle}</a><span>{index < node.blogCategory.length - 1 ? ", " : ""}</span>
                        </li>
                      )}
                    </ul>
                    <div className="author-info">
                      <a href={node.author.linkedin} target="_blank" rel="noreferrer">{node.author.name}</a><span> - {node.publishDate}</span>
                    </div>
                  </article>
                )}
                <Pager pageContext={pageContext} />
                </div>
              </div>
          </div>
        </div>
        
      </BlogContainer>
      <NewsletterCTA />
    </main>
  )
}

export default BlogCategory

export const Head = (pageContext) => {
  return (
    <>
      <Seo 
        key={pageContext.pageContext.category.id} 
        title={`FinTech Law Blog Category: ${pageContext.pageContext.category.categoryTitle}`} 
        description={`The FinTech Law Blog category ${pageContext.pageContext.category.categoryTitle}`} 
        image={pageContext.pageContext.category.categoryImage.url} 
      />
    </>
  )
}
